@import '../../styles/variables';

.custom-file-button {
	input[type="file"] {

		&::-webkit-file-upload-button {
			display: none;
		}

		&::file-selector-button {
			display: none;
		}
	}

	&:hover {
		label {
			background-color: #dde0e3;
			cursor: pointer;
		}
        .form-control {
            box-shadow: none;
            border-color: $border-color;
        }
	}
}
