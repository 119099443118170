@import '../../styles/variables';

.segment-array-group {
  margin-right: $dropdown-column-gap;
  max-width: 255px;
}

.segment-array-item {
  cursor: pointer;
}

.segment-fields-list-flex {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 5px;
  justify-content: space-evenly;
}

.segment-search-items-flex {
  display: flex;
  flex-direction: column;
  gap: $dropdown-section-body-item-gap;
}

.segment-search-item {
  cursor: pointer;
  font-size: $dropdown-section-font-size;
  font-weight: $dropdown-section-font-weight;
  line-height: $dropdown-section-line-height;
}

.segment-search-no-results {
  text-align: center;
  margin-top: 25px;
  font-style: italic;
}
