@import '../variables';

.page-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacer;
}

.page-title {
    font-size: $page-header-font-size;
    font-weight: $page-header-font-weight;
    color: $page-header-font-color;
}

.graph-title {
    font-size: $graph-header-font-size;
    font-weight: $graph-header-font-weight;
    color: $graph-header-font-color;
    margin-bottom: $graph-header-margin-bottom;
}
