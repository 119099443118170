@import '../../../styles/variables';

.edit-de-container-trigger-settings {
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 0.2);
  width: 930px;
  margin: 30px;
  display: flex;
  flex-direction: column;
}

.trigger-settings-header {
  display: grid;
  grid-template-columns: 280px 150px 150px 200px 140px;
  align-items: center;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 2px solid $border-color;
  padding: 0px 5px;
  box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
}

.trigger-settings-header-cell {
  padding: 8px 5px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
