@import '../variables';

.list-group {
    color: $list-group-text-color;
    font-size: $list-group-font-size;
    line-height: $list-group-font-line-height;
    letter-spacing: $list-group-font-letter-spacing;
    overflow: auto;
}

.list-group-flush.list-group-custom-border {
    border: $list-group-border-width solid $list-group-border-color;
    border-radius: $list-group-border-radius;
}

.list-group-item-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

// override bootstrap
.list-group-item-action:focus,
.list-group-item-action:hover {
    border-bottom: 0;
}