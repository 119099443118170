@import '../../styles/variables';

.segment-page {
  .expand-row-controls-group {
    margin-top: 16px;
    padding-bottom: 10px;
  }
}

.segment-rules-container {
  background-color: $segment-table-expanded-row-segment-rules-background-color;
  text-align: left;
  padding: 16px;

  .card-title {
    padding-top: 0;
  }
}

.rules-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 10px;
  grid-gap: 10px;
  grid-row-gap: $spacer;
  margin-top: $spacer;
}

.rule-name {
  grid-column: 1 / span 1;
}
