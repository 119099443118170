.alert-container {
  position: fixed;
  margin-top: 50px;
  z-index: 5;
  top: 0;
  right: 50%;
  transform: translateX(50%);
}

.alert {
  box-shadow: 0 2px 4px rgba(34, 34, 34, 0.4);
}
