@import '../../styles/variables';

$parent-container-height: calc(100vh - 48px);

.brand-page-container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.brand-styles-editor-container {
  overflow: auto;
  min-width: 425px;
  width: 100%;
  height: $parent-container-height;
  display: grid;
  grid-template-columns: auto;
  position: relative;
}

.brand-save-btn {
  top: 19px;
  right: 10px;
  z-index: 1;
  width: 110px;
  margin-left: auto;
}

.brand-categories-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.brand-group-top-spacer {
  padding-top: 80px;
}

.brand-title {
  margin-bottom: 20px;
}

.brand-card {
  margin: 30px 0px;
}

.add-color-container {
  align-items: center;
  gap: 15px;
  width: 100%;
  padding: 4px 12px;
}

.add-color-icon-div {
  width: 27px;
  font-size: 22px;
  color: $purple-gray;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.brand-page-header {
  padding-right: $spacer-xl;
  width: 50%;
}

.email-preview-container {
  min-width: 650px;
  height: $parent-container-height;
}

.brand-title-div {
  justify-self: start;
  text-align: left;
}

.search-results-container {
  .color-row {
    width: 480px;
    justify-content: space-between;
  }
  .color-category {
    font-size: 12px;
    margin-left: 22.5px;
    margin-right: auto;
    margin-top: -6px;
  }
  .form-text-input {
    width: 95%;
    justify-self: end;
  }
  .style-phrases-category {
    font-size: 12px;
    margin-right: auto;
    margin-top: -10px;
  }
}

.search-results-row {
  border-bottom: 1px solid $border-color;
  width: 100%;
  padding: 5px 20px;
}

.preview-dropdown {
  &::after {
      content: $icon-chevron-down;
      font-family: $icon-font;
      font-weight: 900;
      padding-left: 10px;
      vertical-align: middle;
      font-size: 16px;
      color: $email-preview-icon-color;
      float: right;
      pointer-events: none;
  }

  + .dropdown-menu {
      width: 100%;
  }
}

.brand-headers-size-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 1570px) {
  .brand-headers-size-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.brand-buttons-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.brand-buttons-card {
  min-width: 580px;
}