@import '../../styles/variables';

.center {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.searchBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.searchResultContainer {
    height: 60%;
}

.listGroup {
    height: 100%;
}

.searchResultText {
    font-size: $list-group-font-size;
    letter-spacing: $list-group-font-letter-spacing;
    line-height: $list-group-font-line-height;
}

.searchResultItemContainer {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    > *:not(:last-child) {
        padding-right: 15px;
    }
}