@import '../../styles/variables';

.event-row {
    grid-template-columns: 30px 1fr;
    grid-template-rows: 30px 1fr;
    align-items: center;
    column-gap: 15px;
}
.event-icon {
    color: $icon-color;
    font-size: 20px;
    place-self: center; 
}
.event-vertcal-line {
    width: 1px; 
    background-color: $light-blue-gray;
    height: 80%;
    justify-self: center;
    align-self: start;
}
.event-date-time {
    color: $light-blue-gray;
    font-size: 14px;
    padding-bottom: 30px;
}