@import '../../styles/variables';

$rowGap: 20px;

.segment-status-popup {
  .card-body {
    display: flex;
    flex-direction: column;
    gap: $rowGap;
  }

  .card-title {
    margin: 0;
  }
}

.segment-status-item {
  margin: 0;
  gap: 16px;

  &:empty {
    margin-top: #{-$rowGap};
  }

  i,
  .fa-loading-container {
    border-right: 1px solid $border-color2;
    height: 32px;
    width: 40px;
    text-align: center;
    line-height: 32px;
  }

  .fa-loading-container {
    color: $icon-color;
  }

  i::before {
      vertical-align: middle;
  }
}
