@import '../variables';

.table {
    text-align: center;
    margin-bottom: 0;

    // all cells
    th,
    td {
        font-size: $table-font-size;
        font-weight: $table-font-weight;
        line-height: $table-line-height;
        vertical-align: middle;
        padding: 0;

        > div {
            margin-bottom: $table-cell-margin-bottom;
            padding: $table-cell-padding;
        }
    }

    // table header
    thead {
        text-transform: $table-header-text-transform;
        border-bottom: $table-header-border-bottom;

        th > div {
            text-wrap: nowrap;
        }

        th:not(:last-child) > div{
            box-shadow: $table-header-cell-border-right;
        }
    }

    // table body
    tbody {
        // body - all cells
        th, td {
            &:not(:last-child) > div{
                box-shadow: $table-body-cell-border-right;
            }

            > div {
                margin-top: $table-body-cell-row-margin-top;
                margin-bottom: $table-body-cell-row-margin-bottom;
            }
        }

        tr:hover {
            th, td {
                &:not(:last-child) > div {
                    box-shadow: $table-body-cell-border-right-hover;
                }
            }
        }
    }

    // first column
    th:first-child {
        text-align: left;
    }

    .icons-cell-wrapper {
        width: 100px;    // 16px size * 3 icons + 20px gap + 16px*2 padding
        margin-left: auto;
        margin-right: auto;

        .icons-cell-content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        i {
            font-size: 16px;
            cursor: pointer;
            color: $icon-color;
        }
    }

    .th-break-word {
        line-height: 16px;  // 32px height / 2 lines = 16px
    }
}

.table-sm {
    thead {
        text-transform: $table-sm-header-text-transform;
    }
    th, td {
        font-size: $table-sm-cell-font-size;

        > div {
            padding: $table-sm-cell-padding;
        }
    }

    .th-break-word {
        text-align: center;
        line-height: $table-sm-header-line-height-break-word;
        height: $table-line-height;
    }

    // table body - header column
    tbody th > div {
        font-size: $table-sm-body-header-column-font-size;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.table-hover tbody tr {
    &:hover,
    &:hover > * {
        background-color: $table-row-hover-background-color;
    }
}

.left-align-first-column th:first-child {
    > div {
        padding-left: 0;
    }
}


/*
*   Active/focused row
*/
tr,
.table-hover tbody tr {
    &.active-row,
    &.active-row > *,
    &.active-row:hover,
    &.active-row:hover > * {
        background-color: $table-row-active-background-color;
    }
}

.table-hover tbody tr.active-row {
    th, td {
        &:not(:last-child) > div {
            box-shadow: $table-body-cell-border-right-hover;
        }
    }
}
/* END ACTIVE/FOCUSED ROW*/


/*
*   Expanded Row
*/
// header row
.table [aria-expanded="true"] {
    th div {
        padding-left: $table-cell-padding-x;
    }

    th, td {
        &:not(:last-child) > div {
            box-shadow: $table-body-cell-border-right-hover;
        }
    }
}
.table tr,
.table-hover tr {
    &[aria-expanded="true"],
    &[aria-expanded="true"] > *,
    &[aria-expanded="true"]:hover,
    &[aria-expanded="true"]:hover > * {
        background-color: $table-expanded-row-header-background-color;
    }
}

// expanded content
.expand-row-shadow [aria-expanded="true"] + tr td {
    box-shadow: $table-expanded-content-row-box-shadow;
}
.table [aria-expanded="true"],
.table-hover [aria-expanded="true"] {
    + tr,
    + tr > *,
    + tr:hover,
    + tr:hover > * {
        background-color: $table-expanded-row-content-background-color;
    }
}

.expand-row-header {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-bottom: $table-header-border-bottom;
}

.expand-row-controls-group {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.expand-row-controls {
    align-self: center;
    display: flex;
    gap: 20px;
}
/* END EXPANDED ROW*/


.no-hover:hover,
.no-hover:hover > th,
.no-hover:hover > td {
    background-color: initial !important;
}

// overrides bootstrap style
.table>:not(caption)>*>* {
    box-shadow: none;
}
