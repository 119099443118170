@import '../../styles/variables';

.email-preview-iframe {
  width: 650px;
  height: 100%;
}

.email-preview-title-container {
  padding: 10px 0px;
  width: 100%;
  background-color: #F3F4F7;
  border-bottom: 1px solid #BFC5D2;
  position: relative;
  display: flex;
}

.email-preview-settings-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.email-preview-dropdown-container {
  margin: auto;
}

.email-preview-dropdown.btn {
  border-color: transparent;
  text-transform: none;
  color: #B0BAC9 !important;
  background-color: #ffffff;
  padding: 7px 20px;

  &:not(.disabled):active {
      border-color: transparent !important;
  }
  &:focus {
      box-shadow: none;
  }
  &:hover {
    background-color: #ffffff !important;
  }
}

.email-preview-overflow {
  height: 100%;
  width: 100%;
  box-shadow: 0 5px 15px rgb(0 0 0 / 0.2);
  display: flex;
  flex-direction: column;
}

.email-preview-title {
  text-align: center;
  margin: 0px;
  color: $white;
}
