@import '../../styles/variables';

.logo-container,
.new-logo-container {
  display: flex;
  justify-content: center;
}

.new-logo-container {
  width: 100%;
  position: relative;
  cursor: pointer;

  &:hover .logo-div-new {
    opacity: 0.3;
  }
}

.no-logo-div {
  height: 100px;
  width: 100%;
}

.logo-img {
  width: 91px;
  height: 98px;
  object-fit: contain;
}

.small-logo {
  width: 42px;
  height: 42px;
  object-fit: contain;
  margin: 16px 0px;
}

.logo-div-new {
  width: 100%;

  &:hover + .edit-icon-new {
    opacity: 1;
  }
}

.edit-icon-new {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  color: $white;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}
