@import '../../styles/variables';

.pop-up-input-label {
  align-self: flex-start;
  margin: 20px 0px 0px 30px;
}

.email-segment-pop-up-input{
  align-self: center;
}

.segment-status-popup {
  width: 100%;
  text-align: left;
  z-index: 5;
}
