@import '../../styles/variables';

.title {
  text-align: left;
  padding: 20px 20px 20px 30px;
  border-bottom: #C5C5C5 solid 3px;
  background-color: $white !important;
  font-family: $primary-font;
  display: flex;
  justify-content: space-between;
}

.title > h2 {
  margin: 0px !important;
}
