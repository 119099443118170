@import '../../../styles/variables';

.edit-de-row-trigger-settings {
  display: grid;
  grid-template-columns: 280px 150px 150px 200px 140px;
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding: 5px;
}

.trigger-settings-cell {
  display: flex;
  flex-direction: column;
}

.trigger-settings-name-text {
  justify-self: left;
  text-align: left;
  font-size: 14px;
  padding-left: 15px;
}

.trigger-settings-text-input {
  width: 75%;
  align-self: center;
}
