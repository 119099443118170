@import '../variables';

.dropdown-panel-container {
    padding: 0px;
    border: none;

    &.dropdown-menu {
        width: max-content;

        @media (max-width: 1150px) {
            width: calc(100vw - 245px);
            overflow: auto;
        }
    }
}

.dropdown-panel {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 21px 19px;
    border-radius: 10px;
    border: 1px solid $light-blue-gray;
    background: $card-body-background;
}

.dropdown-options {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow: auto;
    max-height: 80%;

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
    }
}

.dropdown-panel-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-right: $dropdown-column-gap;
}

.dropdown-section {
    font-size: $dropdown-section-font-size;
    font-weight: $dropdown-section-font-weight;
    line-height: $dropdown-section-line-height;
}

.dropdown-section-title {
    color: $dropdown-section-header-font-color;
    margin-bottom: $dropdown-section-header-margin-bottom;
}

.dropdown-panel .dropdown-section-body {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    gap: $dropdown-section-body-item-gap;
}
