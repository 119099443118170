@import '../../styles/variables';

.subscriber-details {
    width: 100%;
    min-width: 200px;
}
.subscriber-label {
    color: $light-blue-gray;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
}
.subscriber-value {
    color: $base-font-color;
    width: fit-content;
}
.subscriber-details-row-container {
    gap: 20px;
}
.subscriber-details-row {
    gap: 3px;
}
.subscriber-details-header {
    margin-top: 15px;
    margin-bottom: 35px;
}
.subscriber-card-icon {
    font-size: 44px;
    line-height: 44px;
    color: $purple-gray;
}
.subscriber-color- {
    &-Unsubscribed {
        color: #D8362F;
    }
    &-Bounced {
        color: #E7CC3F;
    }
    &-Active {
        color: #7FE73F;
    }
    &-Held {
        color: #BFBFBF;
    }
}
