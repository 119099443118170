@import '../../../styles/variables';

.multi-graphs-container {
    display: flex;
    flex-direction: column;
}

.multi-graph-label-top {
    display: flex;
    justify-content: space-between;
    margin-left: 50px;
    margin-bottom: 20px;
    line-height: 32px;
    color: $light-blue-gray;
}

.graph-container {
    display: flex;
    align-items: center;
    line-height: 22px;

    &:not(:last-of-type) {
        margin-bottom: 50px;
    }
}

.graph-wrapper {
    min-width: 200px;
    width: 100%;
    min-height: 100px;
    max-height: 150px;
    height: 100%;
}

.graph-label-container {
    min-width: 45px;
}

.label-total-name {
    border-bottom: 1px solid #{$light-blue-gray}99;
    width: fit-content;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.label-total-count {
    font-weight: 700;
}
