// Generate color classes for setting text color, background color, and border color
// Usage: .text--red, background--light-gray, border--dark-gray
$color-class-prefixes: text, background, border;
@each $style in $color-class-prefixes {
    $color: if($style == text, color, #{$style + '-color'}); // color or background-color, border-color
    .#{$style} {
        @include color-classes($color);
    }
}

.background-color {
    @include heatmap-color-classes(background-color);
}

// Create button classes, e.g. .btn-primary
.btn {
    @include button;
    &-primary {
        @include button(primary);
    }
    &-primary-red {
        @include button(primary-red);
    }
    &-secondary,
    &-outline-primary {
        @include button(secondary);
    }
    &-tertiary {
        @include button(tertiary);
    }
    &-sm {
        @include button(null, null, null, null, small);
        &.btn-primary {
            @include button(primary, null, null, null, small);
        }
        &.btn-secondary,
        &.btn-outline-primary {
            @include button(secondary, null, null, null, small);
        }
        &.btn-tertiary {
            @include button(tertiary, null, null, null, small);
        }
    }
    &-lg {
        @include button(null, null, null, null, large);
        &.btn-primary {
            @include button(primary, null, null, null, large);
        }
        &.btn-secondary,
        &.btn-outline-primary {
            @include button(secondary, null, null, null, large);
        }
        &.btn-tertiary {
            @include button(tertiary, null, null, null, large);
        }
    }
    &-block {
        @include full-width;
    }
}

.d-grid {
    display: grid;
}

.disabled,
[disabled] {
    @include disabled;
}
