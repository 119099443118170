@import '../variables';

.card {
    background-color: $card-background;
    border: $card-border;
    text-align: $card-text-algin;
}

.card-title,
.card-title a,
.card-title button {
    font-size: $card-header-font-size;
    font-weight: $card-header-font-weight;
    letter-spacing: $card-header-letter-spacing;
    line-height: $card-header-line-height;
    text-transform: $card-header-text-transform;
    color: inherit;
    background-color: transparent;
    padding: 0;
    border: none;
}

.card-title button {
    text-decoration: underline;
}

.card-title {
    color: $card-header-color;
    margin-bottom: $card-header-margin-bottom;
    padding-top: $card-header-padding-top;
}

.card-body {
    font-size: $card-body-font-size;
    color: $card-body-color;
    letter-spacing: $card-body-letter-spacing;
    line-height: $card-body-line-height;
    padding: $card-body-padding;
    border-radius: $card-body-border-radius;
    background: $card-body-background;
    box-shadow: $card-body-shadow-color;
}

.card-body-gap {
    gap: $card-body-gap;
}

.card-body-gap-lg {
    gap: $card-body-gap-lg;
}

.multi-graphs-card {
    .card-title {
        line-height: $multi-graphs-card-header-line-height;
        letter-spacing: $multi-graphs-card-header-letter-spacing;
        color: $multi-graphs-card-header-color;
        padding-top: $multi-graphs-card-header-padding-top;
        margin-bottom: $multi-graphs-card-header-margin-bottom;
    }

    .card-body {
        padding: $multi-graphs-card-body-padding;
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: $multi-graphs-card-body-font-size;
    }
}

.card-popup {
    border-radius: $card-popup-body-border-radius;
    padding: $card-popup-body-padding;
    .card-title {
        padding-top: 0;
    }
}
