@import '../../styles/variables';

.sidenav-loading-container {
    background-color: #F3F5FF;
    border-top: 2px solid $brand-primary;
    border-bottom: 2px solid $brand-primary;
    margin-top: 10px;
    padding: 20px 10px 20px 24px;

    // nesting this here to override `.sidenav a` font size styling
    .sidenav-loading-message {
        font-size: 12px;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 10px;
    }
}

.sidenav-loading-tooltip {
    z-index: 10;
}