@mixin disabled {
    opacity: 0.6;
    pointer-events: none;
    mix-blend-mode: luminosity;
}

@mixin button($style: "", $customBackground: "", $customText: "", $customBorder: "", $size: "") {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: $button-border-width;
    border-style: solid;
    font-family: $button-font;
    text-transform: $button-text-transform;
    transition: all $transition-speed ease-out;
    text-decoration: none;
    border-radius: $button-border-radius;
    text-align: $button-text-align;
    font-weight: $button-font-weight;
    @if $size == small {
        font-size: $button-sm-font-size;
        line-height: $button-sm-line-height;
        padding: $button-sm-padding-top $button-sm-padding-right $button-sm-padding-bottom $button-sm-padding-left;
        letter-spacing: $button-sm-letter-spacing;
    } @else if $size == large {
        font-size: $button-lg-font-size;
        line-height: $button-lg-line-height;
        padding: $button-lg-padding-top $button-lg-padding-right $button-lg-padding-bottom $button-lg-padding-left;
        letter-spacing: $button-lg-letter-spacing;
    } @else {
        font-size: $button-font-size;
        letter-spacing: $button-letter-spacing;
        line-height: $button-line-height;
        padding: $button-padding-top $button-padding-right $button-padding-bottom $button-padding-left;
    }
    @if $style == primary {
        font-family: $button-primary-font;
        text-transform: $button-primary-text-transform;
        color: $button-primary-color;
        background-color: $button-primary-background;
        border-width: $button-primary-border-width;
        border-radius: $button-primary-border-radius;
        border-color: $button-primary-border-color;
        text-decoration: $button-primary-text-decoration;
        text-align: $button-primary-text-align;
        font-weight: $button-primary-font-weight;
        @if $size == small {
            padding: $button-sm-primary-padding-top $button-sm-primary-padding-right $button-sm-primary-padding-bottom $button-sm-primary-padding-left;
        } @else if $size == large {
            padding: $button-lg-primary-padding-top $button-lg-primary-padding-right $button-lg-primary-padding-bottom $button-lg-primary-padding-left;
        } @else {
            padding: $button-primary-padding-top $button-primary-padding-right $button-primary-padding-bottom $button-primary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-primary-color-hover;
            background-color: $button-primary-background-hover;
            border-color: $button-primary-border-color-hover;
            text-decoration: $button-primary-text-decoration-hover;
        }
        &:not(:disabled):not(.disabled):active { // overrides bootstrap
            color: $button-primary-color-hover;
            background-color: $button-primary-background-hover;
            border-color: $button-primary-border-color-hover;
            text-decoration: $button-primary-text-decoration-hover;
        }
    }
    @if $style == primary-red {
        font-family: $button-primary-font;
        text-transform: $button-primary-text-transform;
        color: $button-primary-color;
        background-color: $button-primary-red-background;
        border-width: $button-primary-border-width;
        border-radius: $button-primary-border-radius;
        border-color: $button-primary-red-border-color;
        text-decoration: $button-primary-text-decoration;
        text-align: $button-primary-text-align;
        font-weight: $button-primary-font-weight;
        @if $size == small {
            padding: $button-sm-primary-padding-top $button-sm-primary-padding-right $button-sm-primary-padding-bottom $button-sm-primary-padding-left;
        } @else if $size == large {
            padding: $button-lg-primary-padding-top $button-lg-primary-padding-right $button-lg-primary-padding-bottom $button-lg-primary-padding-left;
        } @else {
            padding: $button-primary-padding-top $button-primary-padding-right $button-primary-padding-bottom $button-primary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-primary-color-hover;
            background-color: $button-primary-red-background-hover;
            border-color: $button-primary-red-border-color-hover;
            text-decoration: $button-primary-text-decoration-hover;
        }
        &:not(:disabled):not(.disabled):active { // overrides bootstrap
            color: $button-primary-color-hover;
            background-color: $button-primary-red-background-hover;
            border-color: $button-primary-red-border-color-hover;
            text-decoration: $button-primary-text-decoration-hover;
        }
    }
    @if $style == secondary {
        font-family: $button-secondary-font;
        text-transform: $button-secondary-text-transform;
        color: $button-secondary-color;
        border-width: $button-secondary-border-width;
        border-radius: $button-secondary-border-radius;
        background-color: $button-secondary-background;
        border-color: $button-secondary-border-color;
        text-decoration: $button-secondary-text-decoration;
        text-align: $button-secondary-text-align;
        font-weight: $button-secondary-font-weight;
        @if $size == small {
            padding: $button-sm-secondary-padding-top $button-sm-secondary-padding-right $button-sm-secondary-padding-bottom $button-sm-secondary-padding-left;
        } @else if $size == large {
            padding: $button-lg-secondary-padding-top $button-lg-secondary-padding-right $button-lg-secondary-padding-bottom $button-lg-secondary-padding-left;
        } @else {
            padding: $button-secondary-padding-top $button-secondary-padding-right $button-secondary-padding-bottom $button-secondary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-secondary-color-hover;
            background-color: $button-secondary-background-hover;
            border-color: $button-secondary-border-color-hover;
            text-decoration: $button-secondary-text-decoration-hover;
        }
        &:not(:disabled):active {
            color: $button-secondary-color-hover !important;
            background-color: $button-secondary-background-hover !important;
            border-color: $button-secondary-border-color-hover !important;
            text-decoration: $button-secondary-text-decoration-hover;
        }
    }
    @if $style == tertiary {
        font-family: $button-tertiary-font;
        text-transform: $button-tertiary-text-transform;
        color: $button-tertiary-color;
        background-color: $button-tertiary-background;
        border-radius: $button-tertiary-border-radius;
        border-width: $button-tertiary-border-width;
        border-color: $button-tertiary-border-color;
        text-decoration: $button-tertiary-text-decoration;
        text-align: $button-tertiary-text-align;
        font-weight: $button-tertiary-font-weight;
        @if $size == small {
            padding: $button-sm-tertiary-padding-top $button-sm-tertiary-padding-right $button-sm-tertiary-padding-bottom $button-sm-tertiary-padding-left;
        } @else if $size == large{
            padding: $button-lg-tertiary-padding-top $button-lg-tertiary-padding-right $button-lg-tertiary-padding-bottom $button-lg-tertiary-padding-left;
        } @else {
            padding: $button-tertiary-padding-top $button-tertiary-padding-right $button-tertiary-padding-bottom $button-tertiary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-tertiary-color-hover;
            background-color: $button-tertiary-background-hover;
            border-color: $button-tertiary-border-color-hover;
            text-decoration: $button-tertiary-text-decoration-hover;
        }
        &:not(:disabled):active {
            color: $button-tertiary-color-hover !important;
            background-color: $button-tertiary-background-hover !important;
            border-color: $button-tertiary-border-color-hover !important;
            text-decoration: $button-tertiary-text-decoration-hover;
        }
    }
    @if $style == custom {
        background: $customBackground;
        color: $customText;
        border-color: $customBorder;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            background: darken($customBackground, 5%);
            border-color: darken($customBorder, 5%);
        }
    }
}

@mixin full-width() {
    display: block;
    width: 100%;
}

// generate color classnames, e.g. ".color--red, background--red, border--red"
@mixin color-classes($attribute) {
    @each $name, $hex in $colors {
        &--#{"" + $name} {
            #{$attribute}: $hex !important;
        }
    }
}

// generate color classnames, e.g. ".background-color--heatmap-red, .background-color--heatmap-red-10"
@mixin heatmap-color-classes($attribute) {
    @each $name, $hex in $heatmap-colors {
        &--#{"" + $name} {
            #{$attribute}: $hex !important;

            @each $oName, $oHex in $opacity-hex {
                &-#{"" + $oName} {
                    #{$attribute}: #{$hex}#{$oHex} !important;
                }
            }
        }
    }
}
