@import '../../styles/variables';

.table-hover tr.create-btn-row:hover,
.table-hover tr.reopen-create-btn-row:hover {
  background-color: initial;
}

.create-btn-row {
  .tooltip-target-wrapper {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: $table-cell-margin-bottom;
  }
}

.table {
  .editor-row  td > div {
    padding: 0;
  }
}

.segment-table-sm-col {
  width: 15%;
}

// expanded row
.table tr.editor-row,
.table-hover tr.editor-row:hover {
  background-color: $segment-table-expanded-row-background-color;
}

.segment-box-controls {
  border-bottom: $table-header-border-bottom;
  display: flex;
  flex-direction: row;
}