.heatmap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.full-grid-container {
  display: flex;
  flex-direction: column;
}

.bottom-grid-container {
  display: flex;
  flex-direction: row;
}

.day-time-grid {
  display: grid;
  grid-template-columns: repeat(7, 80px);
  grid-template-rows: repeat(24, 25px);
}

.weekday-grid-x {
  display: grid;
  grid-template-columns: 40px repeat(7, 80px);
  grid-template-rows: 25px;
  font-size: 12px;
  border-bottom: 2px solid #D0D0D0;
}

.time-grid-y {
  display: grid;
  grid-template-rows: repeat(24, 25px);
  grid-template-columns: 40px;
  border-right: 2px solid #D0D0D0;
}

.time-y-row {
  width: 40px;
  font-size: 12px;
  text-align: center;
  border-bottom: 2px solid #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heatmap-grid-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-right: 3px;
  border-bottom: 2px solid #E8E8E8;
}

.weekday-header {
  text-align: center;
}
