@import '../../styles/variables';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.config-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
}
.config-name {
    font-size: 13px;
}
.config-name, .config-value {
    width:50%;
}
.config-value > input {
    color: $purple-gray;
    border-width: 0.5px;
    border-radius: 5px;
    height: 32px;
}
.config-value>input:focus {
    color: $purple-gray;
}
