@import '../../styles/variables';

.style-color-container {
  display: grid;
  margin: 8px;
  grid-template-columns: 110px 1fr;
  align-items: center;
  font-size: 14px;
}

.style-color-input-container {
  align-self: flex-end;
  position: relative;
  width: 100%;
}

.style-dropdown-input {
  border-radius: 4px !important;
  padding-right: 50px;
}

.color-swatch-gap {
  gap: 20px;
}

.colors-menu {
  z-index: 20;
}


.styles-card-body-gap {
  gap: 10px;
}