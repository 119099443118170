@import './variables';

body {
    font-family: $primary-font;
    color: $base-font-color;
    font-size: $default-text-font-size;
    line-height: $default-text-line-height;
    letter-spacing: $default-text-letter-spacing;
    background-color: $page-background-color;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* PAGE CONTAINERS */
.App-Container {
    display: flex;
    height: 100vh;
}

.App {
    flex: 1 1 auto;
    overflow: auto;
    height: 100%;
    padding: $spacer-xl $spacer $spacer-xl;
    background-color: $off-white;
}

.page {
    margin-left: $spacer;
    margin-right: $spacer;
}

.page-content {
    text-align: center;
}

.half-by-half {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &.colors-input-container {
        gap: $card-body-gap;

        > div {
            display: flex;
            flex-direction: row;
            gap: $card-body-gap;
            width: 100%;
        }
    }
}

.six-by-four {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: $spacer-xl;

    > div {
        flex: 0 0 auto;
        width: 60%;

        + div {
            flex: 1 1 auto;
        }
    }
}
/* END PAGE CONTAINERS */


/*****
*
*
STYLES PAGE
*
*
******/
/* STYLES PAGE CONTAINERS */
.styles-page {
    display: grid;
    gap: $spacer-xl;
    @extend .page-content;
}

.edit-global-components {
    overflow: auto;
    height: 100%;
    width: 100%;
}

.edit-de-container {
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 0.2);
    width: 525px;
    margin: 30px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 141px);
}
/* END STYLES PAGE CONTAINERS */


/* COLORS AND STYLES COMPONENTS */
.edit-de-title-container {
    padding: 10px 0px;
    width: 100%;
    background-color: $brand-primary;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.edit-de-title {
    margin: 0 0 0 7px;
    color: $white;
}

.colors-message {
    grid-column-start: 1;
    grid-column-end: 3;
}

.edit-de-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.color-swatch {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #222;
    align-self: center;
    min-width: 25px;

    &:hover {
        cursor: pointer;
    }
}

.edit-de-row {
    display: grid;
    margin: 5px 0px;
    align-items: center;
}

.half-by-half-grid {
    grid-template-columns: 1fr 1fr;
}

.one-two-grid {
    grid-template-columns: 1fr 2fr;
}

.style-name-text {
    justify-self: left;
    text-align: left;
}

.edit-de-btn-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -moz-box-shadow: 0 -5px 9px -5px #333;
    -webkit-box-shadow: 0 -5px 9px -5px #333;
    box-shadow: 0 -5px 9px -5px #333;
    z-index: 1;
}

.edit-de-btn {
    margin-top: 14px;
    margin-bottom: 14px;
}
/* END COLORS AND STYLES COMPONENTS */


/* REACT-C0LOR PICKER */
.color-picker-container {
    position: fixed !important;
    z-index: 9;
}

.color-picker-cover {
    position: fixed;
    inset: 0px;
}
/* END REACT-C0LOR PICKER */


/* PHRASES */
.edit-phrases-row {
    display: grid;
    grid-template-columns: 70px 1fr;
    margin: 10px 0px;
    align-items: center;
    font-size: 14px;
}
/* END PHRASES */


/* BRAND SEARCH BAR */
.search-bar-button {
    background-color: $brand-secondary;
    padding-left: 18px;
    padding-right: 18px;
    color: $white;
    border-width: 1px;

    &:hover {
        background-color: $light-blue;
    }
}
/* END BRAND SEARCH BAR */


/* OVERVIEW PAGE */
.send-email-table-data {
    display: flex;
    align-items: center;
    justify-content: center;
}
.overview-send-volume-container {
    width: 800px;
    height: 100%;
    margin: 0;
}
.google-visualization-tooltip {
    margin-left: 60px;
    pointer-events: none;
    white-space: nowrap;
}
/* END OVERVIEW PAGE */


/* TRIGGER SETTINGS */
.left-vertical-divider {
    border-left: 1px solid $light-gray;
}
/* END TRIGGER SETTINGS */


/* OPEN RATE HEAT MAP */
.rate-title {
    margin-bottom: 20px;
    text-align: center;
}
/* END OPEN RATE HEAT MAP */


/* SEGMENTATION */
.segment-box-container {
    box-shadow: 0 5px 15px rgb(0 0 0 / 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 8px 0 10px;
    background-color: $white;
    min-width: 800px;
    align-items: flex-start;
    margin-bottom: 20px;
}
.segment-box-title {
    margin: 10px 0px 15px 8px;
}
.segment-error-icon {
    color: $dark-red;
}
.segment-status-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.segment-status-item {
    display: flex;
    margin: 15px;
    align-items: center;
}
.segment-data-message {
    align-self: center;
    margin-top: 20px 0px;
    font-style: italic;
}
.select-segment-h3 {
    margin-top: 20px;
}
.create-segment-not-allowed {
    cursor: not-allowed;
}
/* END SEGMENTATION */


/* MISC COMPONENTS */
.sticky-container {
    position: sticky;
    height: 0;
    overflow: visible;
    top: 0;
    left: 0;
}
.sticky-content {
    position: relative;
    top: -100%;
}
.loading-icon {
    margin: auto;
}
.title-check-mark {
    color: $success;
}
.dropdown-item:hover {
    cursor: pointer;
}

.overflow-scroll {
    overflow: scroll;
    background-color: $white;
    position: relative;
}
.overflow-scroll:before {
    position: absolute;
    display: block;
}
.modal-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 4;
}

.success-message {
    color: green;
    text-align: center;
}
.special-char-message {
    color: #cc5a5a;
    font-size: 14px;
    margin-left: 16px;
}
/* Hide Number Input Buttons Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.input-number-toggles-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    position: absolute;
    right: 20px;
    bottom: 5px;
  }

  .input-number-toggles {
    cursor: pointer;
    font-size: 18px;
    color: $purple-gray;
    display: flex;
  }

  .input-number-toggles-icon {
    line-height: 12px;
  }
  .icon-gray {
    color: $light-blue-gray;
  }
  .icon-blue {
    color: #2E5BFF;
  }
/* END MISC COMPONENTS */
