@import '../../styles/variables';

.segment-row-loading {
  width: 14px;
  height: 14px;
}

/* ICONS */
.automated-refresh-active {
  color: $green !important;
}

.automated-refresh-paused {
  color: $yellow !important;
}
/* End of ICONS */
