@import '../../styles/variables';

.sidenav,
.sidenav img,
.sidenav .nav-item-name,
.sidenav .sublink {
  transition: all $transition-speed ease-in-out;
}

.sidenav {
  font-family: $primary-font;
  height: 100%;
  background-color: $nav-background;
  width: $sideNavWidth;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 0 0 auto;
  border-right: $nav-border-right;
  overflow-y: auto;
  overflow-x: hidden;     // prevents it popping up when expanding

  a {
    font-size: $nav-link-font-size;
    text-decoration: $nav-link-text-decoration;
    font-weight: $nav-link-font-weight;
    line-height: $nav-link-line-height;
    color: $nav-link-color;
    width: 100%;
    text-transform: capitalize;
    text-align: left;
    text-wrap: nowrap;
  }

  i {
    width: $nav-link-icon-height;
    line-height: $nav-link-icon-height;
    font-size: $nav-link-icon-size;
  }

  .nav-expand-collapse-btn {
    border: none;
    background-color: $nav-expand-collapse-icon-background;
    width: fit-content;
    padding: 8px 24px;
    margin-left: auto;

    i {
      color: $nav-link-icon-color;
      margin: 0;
    }
  }

  // parent links
  > a {
    padding: $nav-link-padding;

    // parent link icon
    > i {
      color: $nav-link-icon-color;
      margin-right: 10px;
    }
  }

  // sub links
  .sublink-group {
    overflow: initial;
  }

  .sublink {
    display: block;
    color: $nav-link-sub-color;
    padding: $nav-link-sub-padding;
    border: none; // override list-group-item style

    &::before {
      font-family: $icon-font;
      content: $icon-circle;
      color: $nav-link-sub-icon-color;
      font-size: $nav-link-sub-icon-size;
      line-height: 1;
      font-weight: 900;
      vertical-align: middle;
      padding: $nav-link-sub-icon-padding;
      margin-right: 5px;
    }
  }


  // active links
  .active-nav {
    background-color: $nav-link-active-background;
    color: $nav-link-active-color;
    box-shadow: $nav-link-active-box-shadow;

    // active parent link icon
    > i {
      color: $nav-link-active-icon-color;
    }
  }

  .active.sublink {
    // active sub link
    color: $nav-link-active-color;
    background-color: $nav-link-sub-active-background;
    box-shadow: $nav-link-sub-active-box-shadow;

    // active sub link icon
    &::before {
      color: $nav-link-sub-active-icon-color;
    }
  }
}

// collapsed side nav
.sidenav-collapsed {
  width: $sideNavCollapsedWidth;
  overflow-x: clip;

  a { // this "removes" the unwanted trailing spaces from anchor tags
    margin-right: -4px;
    line-height: $nav-link-icon-height;
  }

  i {
    margin-right: 0;
  }

  .nav-item-name {
    opacity: 0;
  }

  .sublink {
    height: 0;
    padding: 0;

    &:before {
      opacity: 0;
    }
  }

  .react-tooltip {
    z-index: 10;
  }
}
