@import '../../styles/variables';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.subscribers-search-container {
    margin-bottom: 20px;
}
.subscribers-info {
    display: grid;
    grid-template-columns: 32% 1fr;
    gap: 40px;
    align-items: start;
    padding-bottom: 30px;
}
.subscribers-card-extra-padding {
    padding: 25px;
}