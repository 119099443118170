@import '../../styles/variables';

.rule-name,
.rule-operator,
.rule-value {
  width: 100%;
}

.rule-operator {
  min-width: 170px;
  position: relative;
}

.segment-create-another {
  margin-right: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.input-money-div {
  position: relative;

  > i {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 50%;
    pointer-events: none;
    width: 40px;
    text-align: center;
    font-style: normal;
    padding-bottom: 2px;
    }
}

.delete-rule {
  cursor: pointer;
}
