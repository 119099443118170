@import '../../styles/variables';

.email-preview-settings-container-relative {
    position: relative;
}

.email-preview-settings-container-absolute {
    padding: 15px 20px;
    background-color: #F3F4F7;
    height: calc(100vh - 140px);
    width: 100%;
    position: absolute;
    overflow: auto;
    box-shadow: inset 0px -20px 20px -15px rgba(0,0,0,.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.email-preview-settings-top {
    padding-bottom: 20px;
    font-size: 13px;
}

.email-settings-spinner {
    height: 23px;
    width: 156px;
}

.eye-icon {
    justify-self: end;
    margin-right: 25px;
    cursor: pointer;
    height: 20px;
}

.email-preview-settings-row-details {
    width: 100%;
}

.email-preview-settings-top-title {
    color: $light-blue-gray;
    letter-spacing: .1em;
}

.email-preview-settings-button {
    background-color: rgba(135, 152, 173, 0.1);
    color: $light-blue-gray;
    font-weight: 500;
    line-height: 21px;
    padding: 0px 30px;
    border: 1px solid $light-blue-gray;
    border-radius: 5px;
}

.email-preview-settings-data-columns {
    display: grid;
    grid-template-columns: 12% 1fr;
    margin-bottom: 35px;
}

.email-preview-settings-row-details-name {
    font-size: 13px;
    line-height: 13px;
    margin-bottom: 10px;
}

.email-preview-divider {
    width: 100%;
    height: 1px;
    color: rgba(135, 152, 173, 0.9);
    margin: 7px 0px 5px;
}

.email-preview-settings-row-details-name-data {
    display: grid;
    grid-template-columns: 1fr 30%;
    gap: 30px;
}

.email-preview-input-label {
    font-size: 10px;
    letter-spacing: .1em;
    color: rgba(135, 152, 173, 0.8);
    margin-bottom: 3px;
}

