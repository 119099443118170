@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


/*
* FONTS
*/
$primary-font: 'Rubik', sans-serif;
$secondary-font: 'Arial', sans-serif;
$icon-font: 'Font Awesome 6 Free';
/* END FONTS */


/*
* COLORS
*/
$brand-primary: #3C65FA;
$brand-primary-red: #EC113B;
$brand-secondary: #A3B7C4;

$black: #000000;
$white: #FFFFFF;
$dark-red: #AB1737;
$dark-blue: #0D2123;
$navy-blue: #2E384D;
$purple-gray: #848EB2;
$blue-gray: #8FA3BB;
$light-blue-gray: #8798AD;
$lighter-blue-gray: #{$light-blue-gray}CC;
$light-blue: #C8E2E4;
$lighter-blue: #EBF7F7;
$dark-gray: #333333;
$gray: #3B494C;
$light-gray: #BFBFBF;
$lighter-gray: #D8D8D8;
$white-gray: #F3F4F7;
$off-white: #FAFAFA;

$green: #7FE73F;
$red: #D8362F;
$yellow: #E7CC3F;
$blue: #3091E7;
$success: $green;
$danger: $red;
$warning: $yellow;
$info: $blue;

$colors: (
    'brand-primary': $brand-primary,
    'brand-primary-red': $brand-primary-red,
    'brand-secondary': $brand-secondary,
    'black': $black,
    'white': $white,
    'dark-red': $dark-red,
    'dark-blue': $dark-blue,
    'navy-blue': $navy-blue,
    'purple-gray': $purple-gray,
    'blue-gray': $blue-gray,
    'light-blue-gray': $light-blue-gray,
    'lighter-blue-gray': $lighter-blue-gray,
    'light-blue': $light-blue,
    'lighter-blue': $lighter-blue,
    'dark-gray': $dark-gray,
    'gray': $gray,
    'light-gray': $light-gray,
    'lighter-gray': $lighter-gray,
    'white-gray': $white-gray,
    'off-white': $off-white,
    'green': $green,
    'red': $red,
    'yellow': $yellow,
    'blue': $blue,
    'success': $success,
    'danger': $danger,
    'warning': $warning,
    'info': $info,
);

$heatmap-colors: (
    'heatmap-red': #D8362F,
    'heatmap-green': $green
);

$opacity-hex: (
    '10': 1A,
    '20': 33,
    '30': 4D,
    '40': 66,
    '50': 80,
    '60': 99,
    '70': B3,
    '80': CC,
    '90': E6,
);

$nav-expand-collapse-icon-background: #F3F4F8;
$nav-link-active-background: $white-gray;

$input-background-color: #{$purple-gray}05;
$input-sm-border-color: #E0E7FF;
$input-sm-background-color: #{$input-sm-border-color}33;

$table-body-cell-border-color: #F2F2F2;
$table-row-hover-background-color: #D1D6DD;
$table-row-active-background-color: #D2D6DC;
$table-expanded-row-header-background-color: #E6E8EA;
$table-expanded-row-content-background-color: #F2F2F2;
$segment-table-expanded-row-background-color: transparent;
$segment-table-expanded-row-segment-rules-background-color: #F9F9F9;

$email-preview-border-color: #BFC5D2;
$email-preview-icon-color: $light-blue-gray;
/* END COLORS */


$spacer-sm: 5px;
$spacer: 16px;
$spacer-xl: 24px;
$negative-spacer-sm: -$spacer-sm;
$negative-spacer: -$spacer;
$negative-spacer-xl: -$spacer-xl;


/*
* ICONS
*/
$icon-circle: "\f111";
$icon-chevron-down: "\f078";
/* END ICONS */

/*
* MISC
*/
$transition-speed: 200ms;
$base-font-size: 15px;
$base-line-height: 1.185;
$base-font-color: $black;
$border-radius: 4px;
$border-color: $light-gray;
$border-color2: #e8e8e8;
$page-background-color: $off-white;
$icon-color: $light-blue-gray;
/* END MISC */


/*
* TEXT
*/
// Stand Alone Text Defaults
$default-text-font: $primary-font;
$default-text-font-size: 16px;
$default-text-line-height: 1.4;
$default-text-font-weight: normal;
$default-text-letter-spacing: normal;

// Links
$link-color: $brand-primary;
$link-color-hover: $brand-primary;
$link-font-weight: normal;
$link-text-decoration: underline;
$link-text-decoration-hover: underline;
// BS variable (odd naming convention)
$link-hover-color: $link-color-hover;
/* END TEXT */


/*
* HEADERS
*/
$page-header-font-size: 22px;
$page-header-font-weight: 300;
$page-header-font-color: $navy-blue;
$page-header-line-height: 18px;
$page-header-margin-bottom: 16px;

$graph-header-font-size: 15px;
$graph-header-font-weight: 500;
$graph-header-font-color: $light-blue-gray;
$graph-header-margin-bottom: 16px;

$dropdown-section-font-size: 13px;
$dropdown-section-font-weight: 400;
$dropdown-section-line-height: 22px;
$dropdown-section-header-font-color: $purple-gray;
$dropdown-section-header-margin-bottom: 16px;
$dropdown-section-body-item-font-color: $black;
$dropdown-section-body-item-gap: 10px;
/* END HEADERS */


/*
* BUTTONS
*/
$button-font: $primary-font;
$button-font-size: 13px;
$button-line-height: 21px;
$button-font-weight: 500;
$button-padding-top: 5px;
$button-padding-bottom: 5px;
$button-padding-left: 38px;
$button-padding-right: 38px;
$button-letter-spacing: $default-text-letter-spacing;
$button-text-transform: capitalize;
$button-border-radius: 5px;
$button-border-width: 1px;
$button-text-align: center;

// Primary button
$button-primary-font: $button-font;
$button-primary-font-weight: $button-font-weight;
$button-primary-text-transform: $button-text-transform;
$button-primary-color: $white;
$button-primary-color-hover: $white;
$button-primary-background: $brand-primary;
$button-primary-background-hover: $brand-primary;
$button-primary-border-radius: $button-border-radius;
$button-primary-border-width: $button-border-width;
$button-primary-border-color: $brand-primary;
$button-primary-border-color-hover: $brand-primary;
$button-primary-text-decoration: none;
$button-primary-text-decoration-hover: none;
$button-primary-padding-top: $button-padding-top;
$button-primary-padding-bottom: $button-padding-bottom;
$button-primary-padding-left: $button-padding-left;
$button-primary-padding-right: $button-padding-right;
$button-primary-text-align: $button-text-align;

// Primary Red Button
$button-primary-red-background: $brand-primary-red;
$button-primary-red-background-hover: $dark-red;
$button-primary-red-border-color: $brand-primary-red;
$button-primary-red-border-color-hover: $dark-red;

// Secondary button
$button-secondary-font: $button-font;
$button-secondary-font-weight: bold;
$button-secondary-text-transform: $button-text-transform;
$button-secondary-color: $light-blue-gray;
$button-secondary-color-hover: $white;
$button-secondary-background: $white;
$button-secondary-background-hover: $light-blue-gray;
$button-secondary-border-radius: $button-border-radius;
$button-secondary-border-width: $button-border-width;
$button-secondary-border-color: $light-blue-gray;
$button-secondary-border-color-hover: $light-blue-gray;
$button-secondary-text-decoration: none;
$button-secondary-text-decoration-hover: none;
$button-secondary-padding-top: $button-padding-top;
$button-secondary-padding-bottom: $button-padding-bottom;
$button-secondary-padding-left: $button-padding-left;
$button-secondary-padding-right: $button-padding-right;
$button-secondary-text-align: $button-text-align;

// Tertiary Button
$button-tertiary-font: $button-font;
$button-tertiary-font-weight: $button-font-weight;
$button-tertiary-text-transform: $button-text-transform;
$button-tertiary-color: $black;
$button-tertiary-color-hover: $purple-gray;
$button-tertiary-background: transparent;
$button-tertiary-background-hover: transparent;
$button-tertiary-border-radius: $button-border-radius;
$button-tertiary-border-width: $button-border-width;
$button-tertiary-border-color: transparent;
$button-tertiary-border-color-hover: transparent;
$button-tertiary-text-decoration: underline;
$button-tertiary-text-decoration-hover: underline;
$button-tertiary-padding-top: $button-padding-top;
$button-tertiary-padding-bottom: $button-padding-bottom;
$button-tertiary-padding-left: 0;
$button-tertiary-padding-right: 0;
$button-tertiary-text-align: $button-text-align;

// Small Button
$button-sm-font-size: 14px;
$button-sm-line-height: 18px;
$button-sm-padding-top: $spacer-sm;
$button-sm-padding-bottom: $spacer-sm;
$button-sm-padding-left: 20px;
$button-sm-padding-right: 20px;
$button-sm-letter-spacing: 0.05em;
$button-sm-primary-padding-top: $button-sm-padding-top;
$button-sm-primary-padding-bottom: $button-sm-padding-bottom;
$button-sm-primary-padding-left: $button-sm-padding-left;
$button-sm-primary-padding-right: $button-sm-padding-right;
$button-sm-secondary-padding-top: $button-sm-padding-top;
$button-sm-secondary-padding-bottom: $button-sm-padding-bottom;
$button-sm-secondary-padding-left: $button-sm-padding-left;
$button-sm-secondary-padding-right: $button-sm-padding-right;
$button-sm-tertiary-padding-top: $button-sm-padding-top;
$button-sm-tertiary-padding-bottom: $button-sm-padding-bottom;
$button-sm-tertiary-padding-left: 0;
$button-sm-tertiary-padding-right: 0;

// Large Button
$button-lg-font-size: 13px;
$button-lg-line-height: 21px;
$button-lg-padding-top: 10px;
$button-lg-padding-bottom: 10px;
$button-lg-padding-left: 28px;
$button-lg-padding-right: 28px;
$button-lg-letter-spacing: normal;
$button-lg-primary-padding-top: $button-lg-padding-top;
$button-lg-primary-padding-bottom: $button-lg-padding-bottom;
$button-lg-primary-padding-left: $button-lg-padding-left;
$button-lg-primary-padding-right: $button-lg-padding-right;
$button-lg-secondary-padding-top: $button-lg-padding-top;
$button-lg-secondary-padding-bottom: $button-lg-padding-bottom;
$button-lg-secondary-padding-left: $button-lg-padding-left;
$button-lg-secondary-padding-right: $button-lg-padding-right;
$button-lg-tertiary-padding-top: $button-lg-padding-top;
$button-lg-tertiary-padding-bottom: $button-lg-padding-bottom;
$button-lg-tertiary-padding-left: 0;
$button-lg-tertiary-padding-right: 0;
/* END BUTTONS */


/*
* FORMs
*/
// Inputs
$input-border-color: $purple-gray;
$input-border-color-focus: none;
$input-background-color-focus: $input-background-color;
$input-box-shadow-focus: none;
$input-text-color: $purple-gray;
$input-placeholder-color: rgba($purple-gray, .7);
$input-border-width: 1px;
$input-border-radius: $border-radius;
$input-font-size: 13px;
$input-font-line-height: 22px;
$input-font-letter-spacing: $default-text-letter-spacing;
$input-padding-top: 5px;
$input-padding-bottom: 5px;
$input-padding-left: 20px;
$input-padding-right: 20px;
$input-height: calc(#{$input-font-line-height} + #{$input-padding-top} + #{$input-padding-bottom} + (#{$input-border-width}*2));

// Small input
$input-sm-placeholder-color: rgba($purple-gray, .7);
$input-sm-padding-top: 3px;
$input-sm-padding-bottom: 1px;
$input-sm-padding-left: 20px;
$input-sm-padding-right: 20px;
$input-sm-height: calc(#{$input-font-line-height} + #{$input-sm-padding-top} + #{$input-sm-padding-bottom} + (#{$input-border-width}*2));

// input with icon prefix
$input-icon-color: $icon-color;
$input-icon-font-size: 16px;
$input-icon-height: $input-height;
$input-icon-padding-left: $input-padding-left;
$input-icon-padding-right: 10px;

// Labels
$form-label-color: $navy-blue;
$form-label-font-weight: 400;
$form-label-font-size: 14px;
$form-label-line-height: 22px;
$form-label-margin-bottom: 0;
$form-label-text-transform: capitalize;
$form-label-letter-spacing: $default-text-letter-spacing;

// Help text
$form-help-text-color: $dark-gray;
$form-help-text-font-size: 10px;
$form-help-text-line-height: 12px;
$form-help-text-letter-spacing: 0.05em;
$form-help-text-margin-top: 4px;

// Validation
$form-validation-color: $danger;
$form-validation-font-size: $form-label-font-size;

// Radio buttons and check boxes
$radiocheck-label-font-weight: $form-label-font-weight;
$radiocheck-label-font-size: $form-label-font-size;
$radiocheck-label-text-transform: none;
$radiocheck-label-letter-spacing: $form-label-letter-spacing;
$radiocheck-background-color: $white;
$radiocheck-background-color-selected: $brand-primary;
$radiocheck-background-color-error: lighten($danger, 55%);
$radiocheck-border-color: $border-color;
$radiocheck-border-color-selected: $black;
$radiocheck-border-color-error: $danger;
$radiocheck-icon-color: $white;
$radiocheck-button-size: 20px;
$radiocheck-button-margin: 10px; // spacing between icon and text label
$radiocheck-vertical-spacing: 3px; // the margin between items when more than one are listed
$radiobutton-selected-inset-amount: 6px;

// Select dropdowns
$dropdown-icon-size: 11px;
$dropdown-icon-color: $input-border-color;
/* END FORMS */

/*
* LIST GROUPS
*/
$list-group-border-color: $input-border-color;
$list-group-border-width: $input-border-width;
$list-group-border-radius: $input-border-radius;
$list-group-text-color: $base-font-color;
$list-group-font-size: $input-font-size;
$list-group-font-line-height: $input-font-line-height;
$list-group-font-letter-spacing: $input-font-letter-spacing;
/* END LIST GROUPS */

/*
* TOOLTIPS
*/
$tooltip-background-color: $white;
$tooltip-text-color: $black;
$tooltip-font-size: 10px;
$tooltip-border-radius: $border-radius;
$tooltip-box-shadow: 0 2px 6px rgba($black, 0.1);
$tooltip-padding: 12px 25px;
$tooltip-max-width: 200px;
$tooltip-text-align: center;
$tooltip-opacity: 1;
$tooltip-icon-size: 120%;
/* END TOOLTIPS */


/*
* MODALS
*/
$modal-backdrop-background-color: rgba($black, 0.6);
$modal-background-color: transparent;
$modal-border: none;
$modal-border-radius: $border-radius;
$modal-box-shadow: 0 2px 6px rgba($black, 0.1);
$modal-max-width: 500px;
$modal-sm-max-width: 300px;
$modal-lg-max-width: 900px;
$modal-xl-max-width: 1140px;
$modal-margins-mobile: 8px;
$modal-margins-desktop: $spacer*2 auto;
// Header
$modal-header-padding: 10px 20px 9px;
$modal-header-background-color: $brand-secondary;
$modal-header-text-color: $white;
$modal-header-border-color: transparent;
$modal-header-title-size: 4; // This number should be 1-6, corresponding with h1-h6 headers
$modal-header-close-icon-size: 14px;
// Body
$modal-body-padding: 20px;
$modal-body-background-color: $white;
$modal-body-text-color: $base-font-color;
// Footer
$modal-footer-padding: 0 20px 20px;
$modal-footer-background-color: $white;
$modal-footer-text-color: $base-font-color;
$modal-footer-border-color: transparent;
/* END MODALS */


/*
* CARDS
*/
$card-background: transparent;
$card-border: none;
$card-text-algin: left;

$card-header-color: $lighter-blue-gray;
$card-header-padding-top: 10px;
$card-header-margin-bottom: 4px;
$card-header-font-size: $dropdown-section-font-size;
$card-header-line-height: 18px;
$card-header-letter-spacing: 1.5px;
$card-header-text-transform: uppercase;
$card-header-font-weight: $dropdown-section-font-weight;
$multi-graphs-card-header-color: $base-font-color;
$multi-graphs-card-header-line-height: 32px;
$multi-graphs-card-header-letter-spacing: 0px;
$multi-graphs-card-header-padding-top: 0px;
$multi-graphs-card-header-margin-bottom: 0px;

$card-body-font-size: $default-text-font-size;
$card-body-line-height: $default-text-line-height;
$card-body-letter-spacing: $default-text-letter-spacing;
$card-body-background: $white;
$card-body-border-radius: 5px;
$card-body-shadow-color: 0px 0px 40px -20px rgba(0, 0, 0, 0.25);
$card-body-color: $base-font-color;
$card-body-padding: $spacer;
$card-body-gap: $spacer;
$card-body-gap-lg: $spacer-xl;
$multi-graphs-card-body-padding: 28px;
$multi-graphs-card-body-font-size: 13px;

$card-popup-body-border-radius: 10px;
$card-popup-body-padding: 20px;
/* END CARDS */


/*
* ACCORDION
*/
$accordion-header-font-family: $secondary-font;
$accordion-header-font-size: 14px;
$accordion-header-line-height: 22px;
$accordion-header-icon-color: $black;
$accordion-header-icon-size: 12px;
/* END ACCORDION */


/*
* DROPDOWNS
*/
$dropdown-menu-border: 1px solid $light-gray;
$dropdown-menu-border-radius: $border-radius;
$dropdown-menu-shadow: 0 2px 2px rgba($black, 0.2);
$dropdown-megamenu-border: none;
$dropdown-icon-size: 10px;
$dropdown-item-color: $link-color;
$dropdown-item-border-color: $light-gray;
$dropdown-item-inview-background: $light-gray;
$dropdown-item-inview-color: $link-color-hover;
$dropdown-item-hover-background: $light-gray;
$dropdown-item-hover-color: $link-color-hover;
$dropdown-item-padding: $spacer;
$dropdown-item-closemenu-background-color: $light-gray;
$dropdown-fadein-speed: $transition-speed; // set to zero to disable fade effect
$dropdown-font-size: 14px;
$dropdown-column-gap: 32px;
/* END DROPDOWNS */


/*
* NAVIGATION PANEL
*/
$sideNavWidth: 215px;
$sideNavCollapsedWidth: 64px;

$nav-background: $white;
$nav-border-right: 1px solid $lighter-gray;

// default/parent links
$nav-link-color: $blue-gray;
$nav-link-font-size: 15px;
$nav-link-font-weight: 400;
$nav-link-line-height: 18px;
$nav-link-text-decoration: none;
$nav-link-padding: 19px 24px;
$nav-link-icon-color: $nav-link-color;
$nav-link-icon-size: 16px;
$nav-link-icon-height: 16px;

// sub links
$nav-link-sub-color: $lighter-blue-gray;
$nav-link-sub-padding: 7px 36px;
$nav-link-sub-icon-color: transparent;
$nav-link-sub-icon-size: 6px;
$nav-link-sub-icon-padding: 5px;

// active links
$nav-link-active-color: $purple-gray;
$nav-link-active-box-shadow: inset 3px 0px 0px 0px $nav-link-active-color;
$nav-link-active-icon-color: $nav-link-active-color;

// active sub links
$nav-link-sub-active-background: $nav-background;
$nav-link-sub-active-icon-color: $icon-color;
$nav-link-sub-active-box-shadow: none;
/* END NAVIGATION PANEL */


/*
* TABLE
*/
$table-font-size: 13px;
$table-line-height: 32px;
$table-font-weight: 400;
$table-color: $base-font-color;
$table-expanded-content-row-box-shadow: inset 0px 25px 11px -12px #d8d8d8, inset 0px -25px 11px -12px #d8d8d8;

$table-cell-padding-x: 16px;
$table-cell-padding: 0 $table-cell-padding-x;
$table-cell-margin-bottom: $spacer;

$table-header-border-color: $light-blue-gray;
$table-header-border-bottom: 2px solid $table-header-border-color;
$table-header-cell-border-right: inset -1px 0px 0px 0px $table-header-border-color;
$table-header-text-transform: capitalize;
$table-sm-header-text-transform: uppercase;

$table-body-cell-border-right: inset -1px 0px 0px 0px $table-body-cell-border-color;
$table-body-cell-border-right-hover: inset -1px 0px 0px 0px $white;
$table-body-cell-row-margin-top: calc($table-cell-margin-bottom / 2);
$table-body-cell-row-margin-bottom: $table-body-cell-row-margin-top;

$table-sm-cell-font-size: 10px;
$table-sm-cell-padding: 0 $spacer;
$table-sm-header-line-height-break-word: 13px;
$table-sm-body-header-column-font-size: 12px;
/* END TABLE */
