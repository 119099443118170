@import '../../styles/variables';

.campaign-page .card-body {
  overflow-x: auto;
}

.email-perf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-de-container-email-perf {
  background-color: #fefefa;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 0.2);
  width: 970px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 141px);
}

.email-perf-header {
  display: grid;
  grid-template-columns: 330px 100px 140px 100px 100px 100px 100px;
  align-items: center;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 2px solid $border-color;
  box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
}

.email-perf-header-cell {
  padding: 13px 5px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-row:nth-of-type(even) {
  background-color: #fcfcfc;
}

.email-row:hover {
  cursor: pointer;
  background-color: $light-blue;
}

.email-parent-title-container {
  background-color: $brand-primary;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 30px;
  width: 100%;
}

.email-search {
  width: 350px;
}
