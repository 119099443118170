@import '../../styles/variables';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.overview-page-container {
  display: grid;
  grid-template-columns: repeat(2, 650px);
  justify-content: center;
  justify-items: start;
  width: 100%;
  min-width: 820px; // widest graph 800px + some spacing
  grid-gap: 24px;
  margin-top: 8px;
  margin-bottom: 8px;

  .card-body {
    width: 100%;
    min-width: 476px;   // smallest graph's width and height
    min-height: 405px;
  }

  .sidenav:not(.sidenav-collapsed) ~ .App & {
    @media (max-width: 1571px) {
      grid-template-columns: repeat(1, 650px);
      justify-items: center;

      .wide-module {
        width: fit-content;
      }
    }
  }

  .sidenav.sidenav-collapsed ~ .App & {
      // some users can set computer's scaling settings to larger percentage, which
      // causes max-width to not be inclusive sometimes and having unexpected results, setting it 0.5 helps fix it
    @media (max-width: 1419.5px) {
      grid-template-columns: repeat(1, 650px);
      justify-items: center;

      .wide-module {
        width: fit-content;
      }
    }
  }
}

.graph-loader {
  display: flex;
  justify-content: center;
  height: 100%;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.chart-module,
.heatmap-module {
  width: 100%;
}

.wide-module {
  width: 200%;
}

.graph-title ~ div {
  margin-left: auto;
  margin-right: auto;
}
