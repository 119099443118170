$module-margin: 10px;

.individual-email-page {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}

.email-title-container {
  margin: 5px 0px 5px;
}

.email-title {
  text-align: left;
  margin-bottom: 0px;
}

.email-sub-title {
  font-size: 14px;
  color: gray;
}

.email-funnel-container {
  align-self: center;
  display: grid;
  grid-template-columns: 130px 30px 130px 130px 130px 130px;
  grid-template-rows: 130px 15px 130px 130px 130px 130px 30px 130px;
  width: 680px;
}

.email-analytics-module {
  border-radius: 15px;
  margin: $module-margin;
  box-shadow: 0 5px 15px rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 5px;
}

.email-analytics-pointer:hover {
  cursor: pointer;
}

.email-analytics-not-allowed:hover {
  cursor: not-allowed;
}

.email-analytics-blue {
  border: 2px solid #8A9FE1;
}

.email-analytics-green {
  border: 2px solid #75D6A2;
}

.email-analytics-red {
  border: 2px solid #C73B64;
}

.email-sent-cell {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
}

.email-sent-down-arrow {
  grid-column: 4 / 5;
  grid-row: 2 / 3;
  align-self: center;
  justify-self: center;
}

.email-not-delivered-arrow-container {
  grid-column: 2 / 4;
  grid-row: 3 / 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.email-not-delivered-text {
  margin-bottom: 0px;
}

.email-not-delivered-half-box {
  width: 85%;
  height: 35px;
  border-left: 2px solid gray;
  border-top: 2px solid gray;
  margin-left: 12px;
  margin-top: 5px;
  border-top-left-radius: 3px;
}

.email-not-delivered-arrow {
  margin-left: 5px;
}

.email-delivered-cell {
  grid-column: 4 / 5;
  grid-row: 3 / 4;
}

.email-delivered-arrow-container {
  grid-column: 5 / 7;
  grid-row: 3 / 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.email-delivered-text {
  margin-bottom: 0px;
  margin-right: 30px;
}

.email-delivered-half-box {
  width: 70%;
  height: 35px;
  border-right: 2px solid gray;
  border-top: 2px solid gray;
  margin-left: $module-margin;
  margin-top: 5px;
  border-top-right-radius: 3px;
}

.email-delivered-arrow {
  margin-right: 61px;
  align-self: flex-end;
}

.email-errors-cell {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
}

.email-excluded-cell {
  grid-column: 3 / 4;
  grid-row: 4 / 5;
}

.email-unsub-arrow-container {
  grid-column: 5 / 6;
  grid-row: 4 / 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.email-unsub-text {
  margin-bottom: 0px;
  margin-left: 30px;
}

.email-unsub-half-box {
  width: 50%;
  height: 75px;
  border-left: 2px solid gray;
  border-top: 2px solid gray;
  margin-top: 5px;
  border-top-left-radius: 3px;
  align-self: flex-end;
}

.email-unsub-arrow {
  margin-right: 0px;
  align-self: center;
  margin-bottom: 20px;
  margin-left: 2px;
}
.email-opens-cell {
  grid-column: 6 / 7;
  grid-row: 4 / 5;
}
.email-total-bounce-cell {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
}
.email-soft-bounce-cell {
  grid-column: 3 / 4;
  grid-row: 5 / 6;
}
.email-unsubs-cell {
  grid-column: 5 / 6;
  grid-row: 5 / 6;
}
.email-clicks-arrow-container {
  grid-column: 6 / 7;
  grid-row: 5 / 6;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.email-clicks-arrow-and-line-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 45px;
}
.email-clicks-text {
  margin-bottom: 0px;
  align-self: center;
}
.email-clicks-line {
  width: 2px;
  height: 135px;
  background-color: gray;
  align-self: center;
}
.email-clicks-arrow {
  margin: 0px;
}
.email-hard-bounce-cell {
  grid-column: 1 / 2;
  grid-row: 6 / 7;
}

.email-other-bounce-cell {
  grid-column: 3 / 4;
  grid-row: 6 / 7;
}
.email-clicks-cell {
  grid-column: 6 / 7;
  grid-row: 6 / 7;
}
.email-conversion-arrow-container {
  grid-column: 6 / 7;
  grid-row: 7 / 8;
}
.email-conversions-cell {
   grid-column: 5 / 7;
   grid-row: 8 / 9;
   display: flex;
   flex-direction: row;
}
.email-conversions-half-cell {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 5px;
  + .email-conversions-half-cell {
    margin-left: 5px;
  }
}
.email-conversion-half-box {
  border-left: 2px solid gray;
  border-top: 2px solid gray;
  border-top-left-radius: 3px;
  height: 18px;
  margin-right: 18px;
  margin-top: 8px;
  margin-left: 7px;
  width: 45%;
}
.email-conversions-line {
  border-right: 2px solid gray;
  border-bottom: 2px solid gray;
  width: 5px;
  height: 10px;
  border-bottom-right-radius: 3px;
  margin-left: 49%;
}
.email-converted-text {
  margin-top: 5px;
}

.arrow-text {
  font-size: 12px;
  color: gray;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid gray;
}
.email-top-totals {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.email-bottom-percentages {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.email-module-text {
  margin-bottom: 2px;
  line-height: 1;
  font-size: 12px;
}
.email-module-number {
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  line-height: 1;
  margin-top: 2px;
}
.email-sent-module-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.conversions-loading {
  font-size: 14px;
}
